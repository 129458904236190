<template>
  <div>
    <b-card v-if="!success && !failure">
      <div class="d-flex justify-content-center mb-3">
        <memlist-spinner />
      </div>
    </b-card>

    <!-- checkboxar -->

    <!-- when member enters his region, we suggest the region based on area alloc BUT we show all other companies as well -->

    <!-- SELECTED COMPANY BY REGION, will display its SUBCOMPANIES, so that member MUST select subcompany to the selected REGION company -->

    <!-- ALSO show studentföreningar -->

    <!-- Aktivera flerstegsmedlemskap -->

    <!-- region company selection -->
    <b-card v-if="success">

      <b-row class="reg-header">
        <b-col>
          <h4> {{ $t('PAGES.REGISTRATION.SELECT_REGION') }}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-8">
        <b-col>
          <div class="checkbox-list">
            <template v-for="(company, i) of regions">
              <div :key="i">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="selectedRegionCompanyId"
                      name="selectedRegionCompany"
                      :value="company.company_id"
                      checked="checked"
                    />
                    <span class="mr-2"></span>{{ company.name }}
                  </label>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>


    </b-card>

    <!-- commune/local company selection -->
    <b-card v-if="success && selectedRegionCompanyId !== null" class="mt-4">
      <b-row class="reg-header">
        <b-col>
          <h4> {{$t('PAGES.REGISTRATION.SELECT_LOCAL_COMPANY')}}</h4>
        </b-col>
      </b-row>
      <b-row class="mt-8">
        <b-col>
          <div class="checkbox-list">
            <template v-for="(company, i) of locals[selectedRegionCompanyId]">
              <div :key="i">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="selectedCommuneCompanyId"
                      name="selectedCommuneCompany"
                      :value="company.company_id"
                      checked="checked"
                    />
                    <span class="mr-2"></span>{{ company.name }}
                  </label>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <!-- extra companies -->
    <b-card v-if="success && extras.length > 0" class="mt-4">
      <b-row class="reg-header">
        <b-col>
          <h4> {{ $t('PAGES.REGISTRATION.CAN_ALSO_JOIN') }}</h4>
        </b-col>
      </b-row>

      <b-row class="mt-8">
        <b-col>
          <div class="checkbox-list">
            <template v-for="(company, i) of extras">
              <div :key="i">
                <div class="d-flex align-items-center">
                  <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                    <input
                      type="checkbox"
                      :disabled="company_id === company.company_id"
                      v-model="extraCompanyIds"
                      :value="company.company_id"
                    />
                    <span></span>
                  </label>
                  <span class="ml-3 cursor-pointer mb-4">{{ company.name }}{{ company_id === company.company_id ? ' (Obligatorisk)' : '' }}</span>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}

.reg-header h4 {
  text-transform: uppercase;
  padding: 8px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  color: #6c6c6c;
}
.reg-header {
  padding: 4px;
  background-color: #efeff2;
}


</style>
<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'area-alloc-multi-membership-page',
  props: [
    'page',
    'pageData',
    'company_id',
    'registration',
    'setCompanyIds',
    'formData',
    'requirements'
  ],
  emits: ['setCompanyIds'],
  components: {},
  mixins: [ toasts ],
  data() {
    return {
      extras: [],
      regions: [],
      locals: {},

      company_ids: [], // <- the selected companies

      selectedRegionCompanyId: null,

      selectedCommuneCompanyId: null,

      extraCompanyIds: [],
      region_code: null,
      commune_code: null,

      success: false,
      failure: false,

      cooldown: false
    };
  },
  mounted() {

  },
  watch: {
    'pageData.data.details': function(newVal, oldVal) {
      if (!newVal) {
        return;
      }

      if (newVal.region_code === this.region_code && newVal.commune_code === this.commune_code) {
        return;
      }

      this.loadCompanies(this.company_id, newVal.region_code, newVal.commune_code, newVal.dob);
    },

    selectedCommuneCompanyId(newValue, oldValue) {
      this.setCompanies();
    },
    selectedRegionCompanyId(newValue, oldValue) {
      this.setCompanies();
    },
    extraCompanyIds(newValue, oldValue) {
      this.setCompanies();
    }
  },
  methods: {
    setRegionCodeAndCommuneCode(region_code, commune_code, dob) {
      this.loadCompanies(this.company_id, region_code, commune_code, dob);
    },
    updateFormData(topCompanyId) {
      for (const company of this.regions) {
        if (this.formData.includes(company.company_id)) {
          this.selectedRegionCompanyId = company.company_id;
          if (this.locals[company.company_id]) {
            for (const local of this.locals[company.company_id]) {
              if (this.formData.includes(local.company_id)) {
                this.selectedCommuneCompanyId = local.company_id;
                break;
              }
            }
          }
          break;
        }
      }
      const extraCompanies = [];
      for (const company of this.extras) {
        if (this.formData.includes(company.company_id)) {
          extraCompanies.push(company.company_id);
        }
      }
      if (!extraCompanies.includes(topCompanyId)) {
        extraCompanies.push(topCompanyId);
      }
      this.extraCompanyIds = extraCompanies;
    },
    loadAllocLater() {
      if (this.cooldown || !this.pageData.data.details) {
        return;
      }

      this.success = false;

      var that = this;
      that.cooldown = true;

      setTimeout(function() {
        that.cooldown = false;

        that.loadCompanies(
          that.company_id,
          that.pageData.data.details.region_code,
          that.pageData.data.details.commune_code,
          that.pageData.data.details.dob
        );
      }, 1000);
    },

    getData() {
      return { company_ids: this.company_ids };
    },

    setCompanies() {
      this.company_ids = [];

      if (this.selectedRegionCompanyId !== null) {
        this.company_ids.push(this.selectedRegionCompanyId);
      }

      if (this.selectedCommuneCompanyId !== null) {
        this.company_ids.push(this.selectedCommuneCompanyId);
      }

      for (const cid of this.extraCompanyIds) {
        this.company_ids.push(cid);
      }

      this.$emit('setCompanyIds', this.company_ids);
    },

    loadCompanies(company_id, region_code, commune_code, dob) {
      this.success = false;
      this.failure = false;
      this.region_code = region_code;
      this.commune_code = commune_code;

      axios
        .get(
          `/company/register_multi?company_id=${company_id}&region_code=${region_code}&commune_code=${commune_code}&dob=${dob}`
        )
        .then(res => {
          this.extras = res.data.companies.extras;
          this.regions = res.data.companies.regions;
          this.locals = res.data.companies.locals;

          if (this.formData && this.formData.length > 0) {
            this.updateFormData(company_id);
          } else {
            if (res.data.companies.matched_region_company !== null) {

              //this.company_ids.push(res.data.companies.matched_region_company.company_id);
              this.selectedRegionCompanyId = res.data.companies.matched_region_company.company_id;
            }

            if (res.data.companies.matched_commune_company !== null) {
              //this.company_ids.push(res.data.companies.matched_commune_company.company_id);

              this.selectedCommuneCompanyId = res.data.companies.matched_commune_company.company_id;
            }

            this.extraCompanyIds = [company_id];

            this.setCompanies();
          }

          this.success = true;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta lista på föreningar');
          this.failure = true;
        });
    },

  }
};
</script>
